import React from 'react';
import { compose } from 'redux';
import { isFunction } from 'lodash';
import { connect } from 'react-redux';

import { plainMixpanelActionCreator, mixpanelActionCreator } from '../actions';

const withMixpanelHandler = Comp => ({ mixpanelActionCreator, plainMixpanelAction, ...rest }) => (
  <Comp
    {...rest}
    withMixpanelAction={(
      handler,
      { page, fetch, isSelector = false, selectorProps = {} },
      extraMixpanelProperties = {}
    ) => (...params) => {
      handler(...params);
      if (isFunction(fetch)) {
        if (isSelector) {
          mixpanelActionCreator({ page, fetch }, selectorProps);
        } else {
          plainMixpanelAction({ page, properties: fetch(params.concat(extraMixpanelProperties)) });
        }
      } else {
        plainMixpanelAction({ page, properties: params });
      }
    }}
  />
);

const withWithMixpanelHandler = compose(
  connect(null, { plainMixpanelAction: plainMixpanelActionCreator, mixpanelActionCreator }),
  withMixpanelHandler
);

export default withWithMixpanelHandler;
