/*global SystemJS*/
/*global ENV*/
/*global VERSION*/
/*global TS*/

export const SystemJsErrorMsg = name =>
  `SystemJS is not defined\nIn order to see ${name} start the application in portal mode`;

export const getBundle = ({ name, extension = 'js', host = '', version = '', ts = '' } = {}) => {
  let cdnUrl = host;
  let v = version;
  let t = ts;
  if (typeof ENV == 'object' && ENV.cdnUrl && !host) cdnUrl = ENV.cdnUrl;
  if (typeof VERSION == 'object' && name && !version) v = VERSION[name];
  if (typeof TS !== 'undefined' && TS && !ts) t = TS;
  const path = `${cdnUrl || ''}${name ? '/' + name : ''}/main${v ? '.' + v : ''}.${extension}`;
  const domain = window.location.hostname; // Do not remove, required for vanity url support
  const q = `?ts=${t}${domain}${extension === 'css' ? '!text' : ''}`;
  return SystemJS.import(`${path}${q}`);
};

export const attachStyleFactory = (name = '') => css => {
  const style = document.createElement('style');
  if (name) style.id = name;
  style.type = 'text/css';
  style.appendChild(document.createTextNode(css));
  document.getElementsByTagName('head')[0].appendChild(style);
  return style;
};

export const detachStyle = id => {
  const elem = document.querySelector(`#${id}`);
  elem.parentNode.removeChild(elem);
  return elem;
};
