import mixpanelClient from 'mixpanel-browser';
import { isFunction, isObject, isBoolean, isEmpty, invoke } from 'lodash';
import { PortalSuperPropsSelector, superPropsDataReducer, getUnpopulatedPortalSPList } from './analyticsDB';

// based on to resolve IE issue
// https://stackoverflow.com/questions/7742781/why-does-javascript-only-work-after-opening-developer-tools-in-ie-once

let isInit = false;
let isIdentified;
let isRegistered;
let isPplPropertiesRegistered; //only for for portal newUI, inOrder for it to work on standalone - need to add this ability
let collectedData = {};

export default (token, userId, superProps, isEnabled = () => true) => {
  return store => {
    const state = store.getState();

    if (ENV.isInLiferayPortal) {
      //for hotel in liferay, should remove after completely migrating hotel to new UI
      initLiferayMixpanel(token, userId, superProps);
    } else if (!isInit) {
      init(token, userId, superProps, state);
    }

    return next => action => {
      superPropsDataReducer(action);
      const result = next(action);
      const state = store.getState();
      const { mixpanelData } = action;

      if (isInit && isEnabled() && isObject(mixpanelData)) {
        const { page: recEvent, fetch: recData, nextData } = mixpanelData;

        const data = getData(recData, state);
        if (data) {
          collectedData = { ...data, ...collectedData };
        }

        const event = getEvent(recEvent, state);
        if (event) {
          /*
                     This is done because userId & superProps can be a selector,
                     if the data wasn't initialized in store during the MW initialization
                     we should try and do it before reporting to Mixpanel.
                     */
          if (!ENV.isInLiferayPortal) {
            identify(userId, state);
            register(superProps, state);
          }

          !isIdentified && console.log('User was not identified, reporting anonymously');
          let unPopulatedPortalSPList = getUnpopulatedPortalSPList();
          superPropsErrorGen(isRegistered, unPopulatedPortalSPList);
          let portalSuperProps = PortalSuperPropsSelector();
          peoplePropsRegister(portalSuperProps, unPopulatedPortalSPList);
          track(collectedData, portalSuperProps, event);
          collectedData = getData(nextData, state) || {};
        }
      }
      return result;
    };
  };
};

function init(token, userId, superProps, state) {
  if (!token) return console.log('Cannot initialize client, missing token');

  mixpanelClient.init(token);
  isInit = true;

  identify(userId, state);
  register(superProps, state);
}

function identify(userId, state) {
  if (isBoolean(isIdentified)) return isIdentified;

  if (!isFunction(userId)) {
    userId ? mixpanelClient.identify(userId) : console.error('Cannot identify user, missing user id');
    isIdentified = !!userId;
    return;
  }

  const recUserId = userId(state);
  if (recUserId) {
    mixpanelClient.identify(recUserId);
    isIdentified = true;
  }
}

function register(superProps, state) {
  if (isBoolean(isRegistered)) return isRegistered;

  if (!isFunction(superProps)) {
    superProps
      ? mixpanelClient.register(superProps)
      : console.error('Missing super props, it will not be included in the reports');
    isRegistered = !!superProps;
    return;
  }

  const recSuperProps = superProps(state);
  if (recSuperProps) {
    mixpanelClient.register(recSuperProps);
    isRegistered = true;
  }
}

function getData(data, state) {
  if (isFunction(data)) return data(state);
  if (isObject(data)) return data;
}

function getEvent(event, state) {
  return isFunction(event) ? event(state) : event;
}

export const initLiferayMixpanel = (token, userId, superProps) => {
  if ((userId || superProps) && window.cwtMixpanel) {
    window.cwtMixpanel.cwtInit(token, userId, superProps);
  }
  isInit = true;
};

function track(collectedData, portalSuperProps, event) {
  if (ENV.isInLiferayPortal) {
    if (window.cwtMixpanel) {
      invoke(window.cwtMixpanel, 'track', event, collectedData);
    } else {
      console.error("window.cwtMixpanel wasn't found");
    }
  } else {
    mixpanelClient.track(event, { ...collectedData, ...portalSuperProps });
  }
}

function superPropsErrorGen(isRegistered, unPopulatedPortalSPList) {
  if (!ENV.isInLiferayPortal) {
    //in Liferay, super props initiated in portal
    !isRegistered &&
      !isEmpty(unPopulatedPortalSPList) &&
      console.warn(`some super props collections are empty!! if running
                    in portal mode, the empty super props collections (portal super props) are: [${unPopulatedPortalSPList}] `);
  }
}

function peoplePropsRegister(portalSuperProps, unPopulatedPortalSPList) {
  if (!ENV.isInLiferayPortal && !isPplPropertiesRegistered && isIdentified) {
    //in Liferay, people props initiated in portal
    mixpanelClient.people.set(portalSuperProps);
    if (isEmpty(unPopulatedPortalSPList)) {
      isPplPropertiesRegistered = true;
    } else {
      console.warn(
        `people super props collections are empty!! the empty people props collections (relevant only for newUI) are: [${unPopulatedPortalSPList}] `
      );
    }
  }
}
