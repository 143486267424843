import SystemJS from 'systemjs';

registerDep('single-spa', () => require('single-spa'));
registerDep('lodash', () => require('lodash'));
registerDep('react', () => require('react'));
registerDep('react-dom', () => require('react-dom'));
registerDep('react-redux', () => require('react-redux'));
registerDep('redux', () => require('redux'));
registerDep('systemjs-plugin-text', () => require('systemjs-plugin-text'));
registerDep('styled-components', () => require('styled-components'));
registerDep('cwt-mixpanel-middleware', () => require('cwt-mixpanel-middleware'));

SystemJS.config({
  map: {
    text: 'systemjs-plugin-text',
  },
});

function registerDep(name, requirer) {
  SystemJS.registerDynamic(name, [], false, (_r, _e, _m) => {
    _m.exports = requirer();
  });
}
