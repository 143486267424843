import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { advancedMixpanelActionCreator } from '../actions';
import { useEffect } from 'react';

export const withMixpanel = WrappedComponent => {
  const ComponentWithMixpanel = props => {
    useEffect(() => {
      const { mixpanelAction, fetchProps } = props;
      mixpanelAction(fetchProps);
    }, []);

    return <WrappedComponent {...props} />;
  };

  return ComponentWithMixpanel;
};

export default ({ page, fetch, fetchProps }) =>
  compose(
    connect(() => ({ fetchProps }), { mixpanelAction: advancedMixpanelActionCreator({ page, fetch }) }),
    withMixpanel
  );
