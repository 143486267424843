import { get, compact } from 'lodash';

export const getFormattedLocation = address => {
  if (address.destinationName) {
    return address.destinationName;
  }

  const mainName = get(address, 'name', '');
  const cityName = get(address, 'city.name', '');

  let stateCode = get(address, 'state.code', '');
  if (!stateCode) {
    stateCode = get(address, 'state.id', '');
  }

  const countryName = get(address, 'country.name', '').replace('United States', 'USA');

  let displayText = '';
  if (mainName === cityName) {
    displayText = compact([cityName, stateCode, countryName]).join(', ');
  } else {
    displayText = compact([mainName, cityName, stateCode, countryName]).join(', ');
  }

  return displayText;
};
