import { filter, find, first, get, map, min, uniq, isEmpty, includes, concat } from 'lodash';
import moment from 'moment';

const MISSING_HOTEL_FIELD = 'Missing A Hotel';
const NO_DATE = moment.utc('1970-01-01').format('YYYY-MM-DD');

export const daysToTrip = trips => {
  if (!trips) return 0;
  const now = moment();
  return min(trips.map(trip => Math.abs(now.diff(moment(trip.startDate), 'days')))) || 0;
};

const isCurrentTrip = trip => {
  const now = moment();
  return now.diff(moment(trip.startDate), 'days') >= 0 && now.diff(moment(trip.endDate), 'days') <= 0;
};

export const calcSubUnitDetailsInfo = (subUnitDetails, flipflop) => {
  const anonymizedInfo = get(subUnitDetails, 'anonimized');
  const homeCountry = get(subUnitDetails, 'countryCode', 'No Country defined');
  const sessionStorageFlipFlopFlags = JSON.parse(sessionStorage.getItem('flipFlopFlags'));
  const featureFlagsSource = isEmpty(flipflop) ? sessionStorageFlipFlopFlags : flipflop;
  const isHotelBookingEligible = get(subUnitDetails, ['services', 'CWT Hotel Web'], false);
  const isFlightBookingEnabled = get(subUnitDetails, ['services', 'cwtAirWeb'], false);
  const isCarRentalEnabled =
    get(featureFlagsSource, 'webCarBooking', false) && get(subUnitDetails, ['services', 'myCwtCarWebBooking'], false);
  const isRailBookingEnabled =
    get(featureFlagsSource, 'cwtRailWeb', false) && get(subUnitDetails, ['services', 'myCwtRailWebBooking'], false);

  return {
    'Sub Identifier': get(anonymizedInfo, 'subAnon', 0),
    'Top Identifier': get(anonymizedInfo, 'topAnon', 0),
    'User Identifier': get(anonymizedInfo, 'travelerAnon', 0),
    'Home Country Location': homeCountry,
    'Eligible for hotel booking': isHotelBookingEligible,
    'Eligible for Air Web Booking': isFlightBookingEnabled,
    'Eligible for Car Booking': isCarRentalEnabled,
    'Eligible for Rail Booking': isRailBookingEnabled,
  };
};

export const calcUserData = userData => {
  let rolesArray = [];
  let roles = get(userData, 'roles');
  rolesArray = concat(rolesArray, roles); // since we  are getting a string when there i only one value
  return {
    clientAdmin:
      includes(rolesArray, 'clientAdmin') ||
      includes(rolesArray, 'globalContentAdmin') ||
      includes(rolesArray, 'cwtAdmin'),
    'travel Arranger': includes(rolesArray, 'arranger'),
    traveler: includes(rolesArray, 'traveler'),
    language: get(userData, 'locale', 'no language'),
  };
};

export const calcUpcomingTripsInfo = upcomingTripsObj => {
  const upcomingTrips = filter(upcomingTripsObj, trip => !isCurrentTrip(trip));
  const destinations = calcDestinationsFromTrips(upcomingTrips);
  const nextTrip = first(upcomingTrips);
  const nextTripSegments = get(nextTrip, 'segments');
  const flights = getAirSegments(nextTripSegments);
  const carRentals = getCarSegments(nextTripSegments);
  const meetings = getMeetingSegments(nextTripSegments);
  const rail = getRailSegments(nextTripSegments);
  const hotels = getHotelSegments(nextTripSegments);
  const messages = [].concat.apply(
    [],
    upcomingTrips.map(trip => trip.messages)
  );
  const missingAccomodationMessages = messages.filter(message => message.subType === 'missingAccommodation');

  return {
    'Number of Upcoming Trips': upcomingTrips.length,
    'Upcoming Trips Destinations': destinations,
    'Is There a Next Trip': upcomingTrips.length > 0,
    'Days To Next Trip': daysToTrip(upcomingTrips),
    'Flights In Next Trip': nextTrip ? flights.length : 0,
    'Meetings In Next Trip': nextTrip ? meetings.length : 0,
    'Car Rentals In Next Trip': nextTrip ? carRentals.length : 0,
    'Ground Transportation In Next Trip': nextTrip ? rail.length : 0,
    'Hotels In Next Upcoming Trip': nextTrip ? hotels.length : 0,
    [MISSING_HOTEL_FIELD]: missingAccomodationMessages.length > 0,
    'Missing Hotel Locations': '',
  };
};

export const getCurrentTrip = upcomingTripsObj => {
  return find(upcomingTripsObj, isCurrentTrip);
};

export const calcCurrentTripInfo = upcomingTripsObj => {
  const currentTrip = getCurrentTrip(upcomingTripsObj);
  const segments = get(currentTrip, 'segments');
  const flights = getAirSegments(segments);
  const carRentals = getCarSegments(segments);
  const meetings = getMeetingSegments(segments);
  const rail = getRailSegments(segments);
  const hotels = getHotelSegments(segments);

  return {
    'Is There a Current Trip': !!currentTrip,
    'Current Trip Start Date': currentTrip ? moment(currentTrip.startDate).format('YYYY-MM-DD') : NO_DATE,
    'Current Trip End Date': currentTrip ? moment(currentTrip.endDate).format('YYYY-MM-DD') : NO_DATE,
    'Current Trip Destination': get(currentTrip, 'destination.name', 0),
    'Flights In Current Trip': currentTrip ? flights.length : 0,
    'Meetings In Current Trip': currentTrip ? meetings.length : 0,
    'Car Rentals In Current Trip': currentTrip ? carRentals.length : 0,
    'Ground Transportation In Current Trip': currentTrip ? rail.length : 0,
    'Hotels In Current Trip': currentTrip ? hotels.length : 0,
    'Booking Items In Current Trip': currentTrip ? segments.length : 0,
  };
};

export const calcPastTripsInfo = (pastTrips, isInTrip) => {
  const daysFromLastTrip = isInTrip ? 0 : daysToTrip(pastTrips); // 0 if currently in trip

  return {
    'Number of Past Trips': get(pastTrips, 'length') || 0,
    'Days from Last Trip': daysFromLastTrip, // 0 if currently in trip
  };
};

const calcDestinationsFromTrips = trips => {
  const destinationNames = uniq(map(trips, trip => get(trip, 'destination.name')));
  return isEmpty(destinationNames) ? ['no upcoming trip'] : destinationNames;
};

const getAirSegments = segments => {
  return filter(segments, segment => segment.typeDescription === 'air');
};

const getCarSegments = segments => {
  return filter(segments, segment => segment.typeDescription === 'car' || segment.typeDescription === 'blackCar');
};

const getMeetingSegments = segments => {
  return filter(segments, segment => segment.typeDescription === 'meeting');
};

const getRailSegments = segments => {
  return filter(segments, segment => segment.typeDescription === 'rail');
};

const getHotelSegments = segments => {
  return filter(segments, segment => segment.typeDescription === 'hotel');
};
