import { get, head } from 'lodash';
import {
  calcUpcomingTripsInfo,
  calcCurrentTripInfo,
  calcPastTripsInfo,
  calcUserData,
  calcSubUnitDetailsInfo,
} from './analyticsDataUtil';
import { jwtDecode } from 'cwt-js-utils';

let mixPanelStore = {
  upcomingTrips: false,
  pastTrips: false,
  subUnitDetails: false,
  user: false,
  flipflop: {},
};

const baseProps = {
  'Platform Type': 'Web',
  'Feature Release': 'New home page',
  'Webview Version': 999,
  'Push Type': 'No Push - Web',
  'Production Version': process.env.NODE_ENV === 'production',
  productionVersionType: process.env.NODE_ENV === 'production' ? process.env.productionVersionType : 'not production',
  'Entrance Trigger': 'Web',
};

export const PortalSuperPropsSelector = () => {
  //in case we come from liferay hotel
  if (ENV.isInLiferayPortal) return {};
  //TODO add memoize?
  const { subUnitDetails, upcomingTrips = {}, pastTrips = {}, user, flipflop } = mixPanelStore;
  let firstUserUpcomingTrip = head(Object.values(upcomingTrips));
  let firstUserPastTrip = head(Object.values(pastTrips));
  let userInfo, userRoles, upcomingTripsInfo, currentTrip, pastTripsInfo;
  if (subUnitDetails) {
    userInfo = calcSubUnitDetailsInfo(subUnitDetails, flipflop);
    userRoles = calcUserData(user);
    upcomingTripsInfo = upcomingTrips && calcUpcomingTripsInfo(firstUserUpcomingTrip);
    currentTrip = upcomingTrips && calcCurrentTripInfo(firstUserUpcomingTrip);
    pastTripsInfo = pastTrips && calcPastTripsInfo(firstUserPastTrip);
  }
  const unPopulatedPortalSPList = { 'Missing data collections': getUnpopulatedPortalSPList() };
  return {
    ...userInfo,
    ...upcomingTripsInfo,
    ...currentTrip,
    ...userRoles,
    ...pastTripsInfo,
    ...baseProps,
    ...unPopulatedPortalSPList,
  };
};

export const superPropsDataReducer = action => {
  switch (action.type) {
    case '@flopflip/flags/update':
      mixPanelStore.flipflop = { ...mixPanelStore.flipflop, ...get(action, 'payload') };
      break;
    case 'FETCH_SUB_DETAILS_SUCCESS':
      mixPanelStore.subUnitDetails = get(action, 'response.subUnitDetails');
      break;
    case 'FETCH_UPCOMING_TRIPS_SUCCESS':
      mixPanelStore.upcomingTrips = get(action, 'response.data');
      break;
    case 'FETCH_PAST_TRIPS_SUCCESS':
      mixPanelStore.pastTrips = get(action, 'response.data');
      break;
    case 'LOG_IN_SUCCESS':
      mixPanelStore.user = get(jwtDecode(action.response.access_token), 'payload');
      break;
    case action.key === 'oAuth' && get(action, 'payload.roles') && !action.err && 'persist/REHYDRATE':
      mixPanelStore.user = get(action, 'payload');
      break;
    case 'SET_OAUTH_STATE': //for SSO log-in
      mixPanelStore.user = get(jwtDecode(action.payload), 'payload');
      break;
    default:
      return null;
  }
};

export const getUnpopulatedPortalSPList = () => {
  let unPopulatedPortalSPList = [];
  !mixPanelStore.user && unPopulatedPortalSPList.push('userRoles');
  !mixPanelStore.subUnitDetails && unPopulatedPortalSPList.push('subUnitDetails');
  !mixPanelStore.upcomingTrips && unPopulatedPortalSPList.push('upcomingTrips');
  !mixPanelStore.pastTrips && unPopulatedPortalSPList.push('pastTrips');
  return unPopulatedPortalSPList;
};
