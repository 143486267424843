/**
 * Created by sergeykaplan on 09/08/2018.
 */

const jwtDecode = t => {
  const token = { header: '', payload: '', status: { error: false } };
  if (!t) return token;
  try {
    const _jwtParse = index => {
      const chunk = t.split('.')[index];
      const base64 = chunk.replace(/-/g, '+').replace(/_/g, '/');
      return JSON.parse(b64DecodeUnicode(base64));
    };

    token.header = _jwtParse(0);
    token.payload = _jwtParse(1);
  } catch (err) {
    console.log(err);
    return Object.assign(token, { status: { error: true } });
  }
  return token;
};

function b64DecodeUnicode(str) {
  return decodeURIComponent(
    atob(str)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
}

export { jwtDecode };
