import { isFunction, every, isEmpty } from 'lodash';
import { ADD_MIXPANEL_DATA } from './constants';

export const genSelectorWithPropsAttrVal = selector => props =>
  isFunction(selector) ? state => selector(state, props) : selector;

export const mixpanelActionCreator = ({ page, fetch, nextData }, props = {}) => {
  const { eventProps, dataProps, nextDataProps } = props;
  const isSingleProps = every([eventProps, dataProps, nextDataProps], isEmpty); //Users can send single props that will be send to all selectors or specific props for each selector

  return {
    type: ADD_MIXPANEL_DATA,
    mixpanelData: {
      page: genSelectorWithPropsAttrVal(page)(isSingleProps ? props : eventProps),
      fetch: genSelectorWithPropsAttrVal(fetch)(isSingleProps ? props : dataProps),
      nextData: genSelectorWithPropsAttrVal(nextData)(isSingleProps ? props : nextDataProps),
    },
  };
};

export const actionCreatorWithMixpanelData = (actionCreator, { page, fetch, nextData } = {}) => {
  return (...params) => ({
    ...actionCreator(...params),
    mixpanelData: {
      page: genSelectorWithPropsAttrVal(page)(params),
      fetch: genSelectorWithPropsAttrVal(fetch)(params),
      nextData: genSelectorWithPropsAttrVal(nextData)(params),
    },
  });
};

//I commented  the api bellow this as this function was both in hotel-booking and in digital-web-ui
//TODO: update what is needed in documantation
/*
export const actionWithMixpanelData = (action, { page, fetch, nextData } = {}) => ({
  ...action,
  mixpanelData: { page, fetch, nextData },
});
*/

//this code is for backwards compatibility for hotel booking inside Liferay - dont use it in digital-web-ui
//TODO: remove all apis bellow when hotel in liferay no longer needed
export const dummyMixpanelActionCreator = () => ({
  type: 'ADD_MIXPANEL_DATA',
});

export const collectMixpanelPropertyActionCreator = ({ fetch }) => ({
  type: 'ADD_MIXPANEL_DATA',
  mixpanelData: {
    fetch,
  },
});

export const plainMixpanelActionCreator = ({ page, properties }) => ({
  type: 'ADD_MIXPANEL_DATA',
  mixpanelData: {
    page,
    fetch: { ...properties },
  },
});

export const advancedMixpanelActionCreator = ({ page, fetch }) => fetchProps => ({
  type: 'ADD_MIXPANEL_DATA',
  mixpanelData: {
    page,
    fetch: isFunction(fetch) ? store => fetch(store, fetchProps) : fetch,
  },
});

export const actionWithMixpanelData = (actionCreator, mixpanelData) => {
  return (...params) => ({ ...actionCreator(...params), mixpanelData });
};
